/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Subtitle, Title, Text, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <SiteHeader />

        <Column className="css-1lptt4m --full pb--60 pt--60" name={"x6l58wwbck8"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/f9def89e9acb4d91b8322e05328a0b65_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <TitleMain className="title-box title-box--shadow2 title-box--left fs--102 title-box--invert" content={"<span style=\"color: rgb(255, 255, 255);\">Меню</span><br>"}>
              </TitleMain>

              <Subtitle className="subtitle-box subtitle-box--shadow5 subtitle-box--left ff--2 fs--26 w--500 subtitle-box--invert ls--0 lh--12" style={{"maxWidth":468,"paddingBottom":0}} content={"<span style=\"color: var(--color-blend--05);\">В рамках нашего свадебного ужина мы хотели бы предложить нашим гостям выбор из двух видов меню.<br><br>Мы были бы очень благодарны Вам, если бы Вы дали нам знать о своих вкусовых предпочтениях, а также о ваших планах на ночь после завершения мероприятия.<br><br>Пожалуйста, используйте формуляр ниже.<br></span><br><br><br><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3rdh6kbphf2"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Меню №1"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Первое блюдо"}>
              </Subtitle>

              <Text className="text-box lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--05);\">Два вида обжаренных лангустинов маринованных  в лимонном соке с салатом из итальянских помидор</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Второе блюдо"}>
              </Subtitle>

              <Text className="text-box ff--2 lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Равиоли \"Savoia\" с грибами Порчини, соусом из коньяка, тимьяном и грецкими орехами</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Третье блюдо"}>
              </Subtitle>

              <Text className="text-box ff--2 lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--05);\">Сибас с лавандой, морковью и свеклой</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k8sn75njv9h"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"var(--color-blend--05)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"3rdh6kbphf2"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Меню №2"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Первое блюдо"}>
              </Subtitle>

              <Text className="text-box lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--05);\">Базиликовая панна котта с томатным соусом</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Второе блюдо"}>
              </Subtitle>

              <Text className="text-box ff--2 lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--05);\">Фиоккетти с начинкой из бекона и горгонзолы с миндалем и грушами карамелизированными в вине Примитиво</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Третье блюдо"}>
              </Subtitle>

              <Text className="text-box ff--2 lh--12" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--05);\">Стейк из говядины в беконе с пюре из сельдерея, фиолетовым картофелем и соусом демиглас с ароматом грибов</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k8sn75njv9h"} layout={"l6"}>
          
          <SeparateLineWrap style={{"backgroundColor":"var(--color-blend--05)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"mmwh5gqy5a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"Меню &amp; Проживание&nbsp;"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Благодарим Вас за ваши ответы"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --shadow2" style={{"backgroundColor":"var(--white)"}}>
              
              <ContactForm className="--shape2 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Имя, Фамилия","type":"text","required":true,"placeholder":"Пожалуйста, напишите своё имя и фамилию"},{"name":"Сообщите нам, какой вариант меню Вы предпочитаете","type":"select","options":["Меню №1","Меню №2"],"required":true,"placeholder":"Выберите вариант ответа"},{"name":"Сообщите нам, если бы Вы хотели переночевать на территории замка. Проживание предоставляется женихом и невестой.","type":"select","options":["Да, я остаюсь на ночь.","Нет, мне не требуется номер для проживания."],"required":true,"placeholder":"Выберите вариант ответа"},{"name":"Желаете ли Вы остаться на завтрак в замке на следующий день после свадьбы","type":"select","options":["Да","Нет"],"required":true,"placeholder":"Выберите вариант ответа"},{"name":"Сообщите нам, если у Вас есть аллергия на какой-либо из продуктов меню","type":"text"},{"name":"Отправить ответы.","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-oipkav --center pb--80 pt--60" name={"paticka"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32995/4c0aec4ece424192a143bd4346aed0d2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box title-box--shadow4" content={"<span style=\"color: var(--white);\">Ждем Вас с нетерпением!</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}